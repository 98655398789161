import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const validBrowser = !browser.isBrowser("Internet Explorer");

if (!validBrowser) {
    document.body.setAttribute('class', '');
    document.body.style.overflow = 'hidden';
    document.body.innerHTML = `<div class="browser-not-supported">
        <div class="browser-not-supported__center">
            <h1 class="browser-not-supported__h1">Sorry, but Internet Explorer is no longer supported.</h1>
            <h2 class="browser-not-supported__h2">For the best experience, it's important to use the latest browser. <br>To continue using the website, please upgrade to another browser:</h2>
            <table class="browser-not-supported__table">
                <tbody>
                    <tr>
                        <td class="browser-not-supported__td"><a href="https://www.google.co.uk/chrome/"><img src="/img/browser-logos/chrome.png" alt="Chrome"></a></td>
                        <td class="browser-not-supported__td"><a href="https://www.mozilla.org/en-GB/firefox/new/"><img src="/img/browser-logos/firefox.png" alt="Firefox"></a></td>
                        <td class="browser-not-supported__td"><a href="https://www.microsoft.com/en-gb/edge"><img src="/img/browser-logos/edge.png" alt="Edge"></a></td>
                        <td class="browser-not-supported__td"><a href="https://support.apple.com/downloads/safari"><img src="/img/browser-logos/safari.png" alt="Safari"></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`;
}